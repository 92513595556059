import ProfilePicture, { Size } from "components/ProfilePicture";
import { EthereumAccount } from "types";

interface ProfilePicturesRowProps {
    accounts: EthereumAccount[];
    size?: Size;
}

export default function ProfilePicturesRow({
    accounts,
    size = "extrasmall",
}: ProfilePicturesRowProps): JSX.Element | null {
    if (!accounts.length) return null;

    return (
        <div className="flex flex-row pr-2">
            {accounts.slice(0, 5).map((account, index) => (
                <div key={index} className="-mr-2">
                    <ProfilePicture size={size} account={account} />
                </div>
            ))}
        </div>
    );
}

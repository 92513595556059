import { RemintedNft } from "client/hooks/remint/useRemint";
import useTwitter from "client/hooks/useTwitter";
import Button from "components/button";
import Link from "next/link";
import { ChainId } from "server/services/ethereum/constants";

interface RemintSuccessToastContentProps {
    nft: RemintedNft;
}

export default function SuccessToastContent({
    nft,
}: RemintSuccessToastContentProps): JSX.Element {
    const { isConnected } = useTwitter();
    const imageUrl = nft.imagePreviewLargeUrl ?? nft.imageUrl;

    return (
        <div className="flex flex-col p-3 gap-5 items-center">
            <div className="flex gap-3 items-center w-full">
                {imageUrl && (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                        src={imageUrl}
                        alt="NFT"
                        className="h-16 w-16 rounded-xs"
                    />
                )}
                <div>You reminted {nft.name}!</div>
            </div>
            <div className="flex gap-2 w-full">
                <a
                    href={generatePrefilledTweetUrl(nft)}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full"
                >
                    <Button className="bg-twitterblue border-0 w-full">
                        Tweet
                    </Button>
                </a>
                <a
                    href={generatePrefilledCastUrl(nft)}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full"
                >
                    <Button className="!bg-farcasterpurple border-0 w-full">
                        Cast
                    </Button>
                </a>
            </div>
            {!isConnected && (
                <Link href="/?showTwitterModal=true" className="text-gray-600">
                    Connect your Twitter ↗
                </Link>
            )}
        </div>
    );
}

function generatePrefilledTweetUrl(nft: RemintedNft): string {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append(
        "text",
        `I just reminted ${nft.name} on @homage_xyz!\n`
    );
    urlSearchParams.append(
        "url",
        `https://homage.xyz/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`
    );

    return `https://twitter.com/intent/tweet?${urlSearchParams}`;
}

function generatePrefilledCastUrl(nft: RemintedNft): string {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append("text", `I just reminted ${nft.name} on @homage!`);
    urlSearchParams.append(
        "embeds[]",
        `https://homage.xyz/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`
    );

    return `https://warpcast.com/~/compose?${urlSearchParams}`;
}

import { track } from "client/lib/analytics";
import useToast from "context/toast";
import { useCallback } from "react";

export default function useShare(url: string | false, location: string) {
    const { toast } = useToast();

    return useCallback(async () => {
        if (!url) return;

        track("click:share", { url, location });

        const shareData = {
            url,
        };

        if ("canShare" in navigator && navigator.canShare(shareData)) {
            try {
                // This throws if the user closes the share dialog, so silence the error
                await navigator.share(shareData);
            } catch {}

            return;
        }

        await navigator.clipboard.writeText(url);
        toast({
            content: <div className="px-4 py-2">Copied link</div>,
        });
    }, [url, toast, location]);
}

import {
    prepareWriteL2ReplicaFactory,
    writeL2ReplicaFactory,
} from "contracts/generated";
import { NftIdentifier } from "types";

export default async function deployAndRemint(
    nft: NftIdentifier,
    remintPrice: bigint
) {
    // TODO: wagmi codegen hooks seem to return incompatible types...
    const { request, mode } = await prepareWriteL2ReplicaFactory({
        functionName: "deployReplicaAndRemint",
        args: [nft.address, nft.tokenId],
        value: remintPrice,
    });

    return writeL2ReplicaFactory({
        ...request,
        mode,
        gas: 1_000_000n,
    });
}

import useChangeConnectedChain from "client/hooks/change-connected-chain";
import InsufficientFundsErrorToastContent from "client/hooks/remint/InsufficientFundsErrorToastContent";
import useUser from "client/hooks/useUser";
import { track } from "client/lib/analytics";
import { processTransaction } from "client/lib/transactions";
import { getKey } from "components/Gallery";
import useToast from "context/toast";
import { readHomageProtocolConfig } from "contracts/generated";
import { Address } from "lib/zod/types";
import { useCallback } from "react";
import { ChainId } from "server/services/ethereum/constants";
import type { Nft } from "server/services/nft";
import { mutate } from "swr";
import { unstable_serialize } from "swr/infinite";
import {
    ContractFunctionExecutionError,
    TransactionExecutionError,
} from "viem";
import { waitForTransaction } from "wagmi/actions";
import deployAndRemint from "./deployAndRemint";
import ErrorToastContent from "./ErrorToastContent";
import remint from "./remint";
import SuccessToastContent from "./SuccessToastContent";

export type RemintedNft = Pick<
    Nft,
    "address" | "tokenId" | "name" | "imageUrl" | "imagePreviewLargeUrl"
>;

export default function useRemint() {
    const { user } = useUser();
    const { toast } = useToast();
    const changeConnectedChain = useChangeConnectedChain();

    return useCallback(
        async (nft: RemintedNft, remintContractAddress: Address | null) => {
            if (!user) throw new Error("Can't remint without a connected user");

            await changeConnectedChain(ChainId.Optimism);

            // TODO: get from BE
            const remintPrice = await readHomageProtocolConfig({
                functionName: "remintPrice",
            });

            let hash;
            try {
                ({ hash } = remintContractAddress
                    ? await remint(remintContractAddress, remintPrice)
                    : await deployAndRemint(nft, remintPrice));
            } catch (e) {
                if (
                    e instanceof ContractFunctionExecutionError &&
                    e.details.includes("insufficient funds")
                ) {
                    track("event:remint:error", { reason: "No optimism ETH" });
                    toast({
                        content: <InsufficientFundsErrorToastContent />,
                    });
                } else if (e instanceof TransactionExecutionError) {
                    track("event:remint:error", {
                        reason: "User rejected transaction",
                    });
                    toast({
                        content: (
                            <div className="px-2.5 py-1">
                                Transaction rejected
                            </div>
                        ),
                    });
                } else {
                    track("event:remint:error", { reason: "Other" });
                    toast({
                        content: (
                            <div className="px-2.5 py-1">
                                An unknown error occurred
                            </div>
                        ),
                    });
                }
                throw e;
            }

            try {
                await waitForTransaction({ hash });

                await processTransaction(hash);

                await Promise.all([
                    mutate(`/api/nfts/${nft.address}/${nft.tokenId}`),
                    mutate(
                        unstable_serialize(getKey(user.address, "reminted")),
                        { revalidate: false }
                    ),
                ]);

                track("event:remint:success");
                toast({
                    content: <SuccessToastContent nft={nft} />,
                    duration: 30000,
                });
            } catch (e) {
                track("event:remint:error", {
                    reason: "Transaction processing error",
                });
                toast({
                    content: (
                        <ErrorToastContent
                            chainId={ChainId.Optimism}
                            hash={hash}
                        />
                    ),
                });

                throw e;
            }
        },
        [changeConnectedChain, user, toast]
    );
}

import { ChainIcon } from "connectkit";
import { ChainId } from "server/services/ethereum/constants";

export default function InsufficientFundsErrorToastContent(): JSX.Element {
    return (
        <div className="px-2.5 py-1">
            <div className="text-error-600 mb-2 text-lg">
                Insufficient Optimism ETH
            </div>
            <a
                href="https://app.optimism.io/bridge/deposit"
                target="_blank"
                rel="noreferrer"
                className="underline"
            >
                <div className="flex gap-1.5 items-center">
                    <ChainIcon id={ChainId.Optimism} size={32} />
                    <span className="underline">Bridge ETH to Optimism ↗</span>
                </div>
            </a>
        </div>
    );
}

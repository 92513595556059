// Generated by @wagmi/cli@1.0.3 on 7/13/2023 at 1:35:00 PM
import {
    readContract,
    ReadContractConfig,
    writeContract,
    WriteContractMode,
    WriteContractArgs,
    WriteContractPreparedArgs,
    WriteContractUnpreparedArgs,
    prepareWriteContract,
    PrepareWriteContractConfig,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CreatorRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x70027A922d4d38f6A5693d55E72D58138cc13a9f)
 */
export const creatorRegistryABI = [
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            { name: 'nftContract_', internalType: 'address', type: 'address' },
            { name: 'tokenId_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'getCreatorOf',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'pure',
        type: 'function',
        inputs: [
            { name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' },
        ],
        name: 'supportsInterface',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    },
] as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x70027A922d4d38f6A5693d55E72D58138cc13a9f)
 */
export const creatorRegistryAddress = {
    1: '0x70027A922d4d38f6A5693d55E72D58138cc13a9f',
} as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x70027A922d4d38f6A5693d55E72D58138cc13a9f)
 */
export const creatorRegistryConfig = {
    address: creatorRegistryAddress,
    abi: creatorRegistryABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// HomageProtocolConfig
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export const homageProtocolConfigABI = [
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'previousAdmin',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'newAdmin',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'AdminChanged',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'beacon',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'BeaconUpgraded',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'version',
                internalType: 'uint8',
                type: 'uint8',
                indexed: false,
            },
        ],
        name: 'Initialized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'previousOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'newOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'OwnershipTransferred',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'implementation',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'Upgraded',
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'homageProtocolTeam',
        outputs: [
            { name: '', internalType: 'address payable', type: 'address' },
        ],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'homageProtocolTreasury',
        outputs: [
            { name: '', internalType: 'address payable', type: 'address' },
        ],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'homageToken',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'initializeParams',
                internalType: 'struct IHomageProtocolConfig.InitializeParams',
                type: 'tuple',
                components: [
                    { name: 'owner', internalType: 'address', type: 'address' },
                    {
                        name: 'initialMintPrice',
                        internalType: 'uint256',
                        type: 'uint256',
                    },
                    {
                        name: 'priceInflationMultiplier',
                        internalType: 'uint256',
                        type: 'uint256',
                    },
                    {
                        name: 'remintProceedsTreasury',
                        internalType: 'address payable',
                        type: 'address',
                    },
                    {
                        name: 'homageProtocolTreasury',
                        internalType: 'address payable',
                        type: 'address',
                    },
                    {
                        name: 'l2TokenClaimBridge',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'l2EventLogger',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'l2ReplicaFactory',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'l2ReplicaRegistry',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'l2ReplicaBeacon',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'l1TokenClaimBridge',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'homageToken',
                        internalType: 'address',
                        type: 'address',
                    },
                    {
                        name: 'homageProtocolTeam',
                        internalType: 'address payable',
                        type: 'address',
                    },
                ],
            },
        ],
        name: 'initialize',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l1TokenClaimBridge',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l2EventLogger',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l2ReplicaBeacon',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l2ReplicaFactory',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l2ReplicaRegistry',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'l2TokenClaimBridge',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'owner',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'proxiableUUID',
        outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'remintPrice',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'remintProceedsTreasury',
        outputs: [
            { name: '', internalType: 'address payable', type: 'address' },
        ],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'homageProtocolTeam_',
                internalType: 'address payable',
                type: 'address',
            },
        ],
        name: 'setHomageProtocolTeam',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'homageProtocolTreasury_',
                internalType: 'address payable',
                type: 'address',
            },
        ],
        name: 'setHomageProtocolTreasury',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'homageToken_', internalType: 'address', type: 'address' },
        ],
        name: 'setHomageToken',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l1TokenClaimBridge_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL1TokenClaimBridge',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l2EventLogger_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL2EventLogger',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l2ReplicaBeacon_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL2ReplicaBeacon',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l2ReplicaFactory_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL2ReplicaFactory',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l2ReplicaRegistry_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL2ReplicaRegistry',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'l2TokenClaimBridge_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'setL2TokenClaimBridge',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'remintPrice_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'setRemintPrice',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'remintProceedsTreasury_',
                internalType: 'address payable',
                type: 'address',
            },
        ],
        name: 'setRemintProceedsTreasury',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'newOwner', internalType: 'address', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'newImplementation',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'upgradeTo',
        outputs: [],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            {
                name: 'newImplementation',
                internalType: 'address',
                type: 'address',
            },
            { name: 'data', internalType: 'bytes', type: 'bytes' },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
    },
] as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export const homageProtocolConfigAddress = {
    10: '0xfffF7f0451154Ae5412558190a9e2709A4d32C88',
} as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export const homageProtocolConfigConfig = {
    address: homageProtocolConfigAddress,
    abi: homageProtocolConfigABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// L2EventLogger
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x4066b02742f352f67DcACB4D7D74398167Aa6f1A)
 */
export const l2EventLoggerABI = [
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'remintProceedsTreasury',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNftAddress',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'tokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'CanonicalNftCreatorProceedsClaimable',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'remintProceedsTreasury',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNftAddress',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'tokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'CanonicalNftOwnerProceedsClaimable',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'l2TokenClaimBridge',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNftsHash',
                internalType: 'bytes32',
                type: 'bytes32',
                indexed: false,
            },
            {
                name: 'tokenIdsHash',
                internalType: 'bytes32',
                type: 'bytes32',
                indexed: false,
            },
            {
                name: 'beneficiary',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'etherAmount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'ClaimEtherForMultipleNftsMessageFinalized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'l2TokenClaimBridge',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNftsHash',
                internalType: 'bytes32',
                type: 'bytes32',
                indexed: false,
            },
            {
                name: 'tokenIdsHash',
                internalType: 'bytes32',
                type: 'bytes32',
                indexed: false,
            },
            {
                name: 'beneficiary',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'etherAmount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'ClaimOwnerEtherForMultipleNftsMessageFinalized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'remintProceedsTreasury',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'beneficiary',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'OwnerRemintProceedsClaimed',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'homageProtocolTreasury',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNft',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'ProtocolFeeReceived',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'remintProceedsTreasury',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'beneficiary',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'RemintProceedsClaimed',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'replicaFactory',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'replica',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'ReplicaDeployed',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'to',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNft',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'replicaNft',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'replicaTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'remintPrice',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'ReplicaMinted',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'replicaRegistry',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalNftContract',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'replica',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'ReplicaRegistered',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'replica',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'canonicalTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'replicaTokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'ReplicaTransferred',
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftAddress_',
                internalType: 'address',
                type: 'address',
            },
            { name: 'tokenId_', internalType: 'uint256', type: 'uint256' },
            { name: 'amount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitCanonicalNftCreatorProceedsClaimable',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftAddress_',
                internalType: 'address',
                type: 'address',
            },
            { name: 'tokenId_', internalType: 'uint256', type: 'uint256' },
            { name: 'amount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitCanonicalNftOwnerProceedsClaimable',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftsHash_',
                internalType: 'bytes32',
                type: 'bytes32',
            },
            { name: 'tokenIdsHash_', internalType: 'bytes32', type: 'bytes32' },
            { name: 'beneficiary_', internalType: 'address', type: 'address' },
            { name: 'etherAmount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitClaimEtherForMultipleNftsMessageFinalized',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftsHash_',
                internalType: 'bytes32',
                type: 'bytes32',
            },
            { name: 'tokenIdsHash_', internalType: 'bytes32', type: 'bytes32' },
            { name: 'beneficiary_', internalType: 'address', type: 'address' },
            { name: 'etherAmount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitClaimOwnerEtherForMultipleNftsMessageFinalized',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'beneficiary_', internalType: 'address', type: 'address' },
            { name: 'amount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitOwnerRemintProceedsClaimed',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'canonicalNft_', internalType: 'address', type: 'address' },
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            { name: 'amount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitProtocolFeeReceived',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'beneficiary_', internalType: 'address', type: 'address' },
            { name: 'amount_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitRemintProceedsClaimed',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'replica_', internalType: 'address', type: 'address' },
        ],
        name: 'emitReplicaDeployed',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'to_', internalType: 'address', type: 'address' },
            { name: 'canonicalNft_', internalType: 'address', type: 'address' },
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            {
                name: 'replicaTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            { name: 'remintPrice_', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'emitReplicaMinted',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftContract_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            { name: 'replica_', internalType: 'address', type: 'address' },
        ],
        name: 'emitReplicaRegistered',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            {
                name: 'replicaTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'emitReplicaTransferred',
        outputs: [],
    },
] as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x4066b02742f352f67DcACB4D7D74398167Aa6f1A)
 */
export const l2EventLoggerAddress = {
    10: '0x4066b02742f352f67DcACB4D7D74398167Aa6f1A',
} as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x4066b02742f352f67DcACB4D7D74398167Aa6f1A)
 */
export const l2EventLoggerConfig = {
    address: l2EventLoggerAddress,
    abi: l2EventLoggerABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// L2Replica
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const l2ReplicaABI = [
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'owner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'approved',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'tokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: true,
            },
        ],
        name: 'Approval',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'owner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'operator',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'approved',
                internalType: 'bool',
                type: 'bool',
                indexed: false,
            },
        ],
        name: 'ApprovalForAll',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'version',
                internalType: 'uint8',
                type: 'uint8',
                indexed: false,
            },
        ],
        name: 'Initialized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'from',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'to',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'tokenId',
                internalType: 'uint256',
                type: 'uint256',
                indexed: true,
            },
        ],
        name: 'Transfer',
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'to', internalType: 'address', type: 'address' },
            { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'canonicalTokenId',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'contractURI',
        outputs: [{ name: '', internalType: 'string', type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'getNumRemints',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'homageProtocolConfig',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'homageProtocolConfig_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'originalTokenContract_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'originalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'initialize',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            { name: 'owner', internalType: 'address', type: 'address' },
            { name: 'operator', internalType: 'address', type: 'address' },
        ],
        name: 'isApprovedForAll',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            { name: 'to_', internalType: 'address', type: 'address' },
            {
                name: 'mintAttributedTo_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'refundRecipient_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'mintReplica',
        outputs: [
            {
                name: 'replicaTokenId',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'name',
        outputs: [{ name: '', internalType: 'string', type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'originalTokenContract',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            {
                name: 'replicaTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'remintNumber',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'from', internalType: 'address', type: 'address' },
            { name: 'to', internalType: 'address', type: 'address' },
            { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'from', internalType: 'address', type: 'address' },
            { name: 'to', internalType: 'address', type: 'address' },
            { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
            { name: '_data', internalType: 'bytes', type: 'bytes' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'operator', internalType: 'address', type: 'address' },
            { name: 'approved', internalType: 'bool', type: 'bool' },
        ],
        name: 'setApprovalForAll',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            { name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' },
        ],
        name: 'supportsInterface',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    },
    {
        stateMutability: 'pure',
        type: 'function',
        inputs: [],
        name: 'symbol',
        outputs: [{ name: '', internalType: 'string', type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ name: '', internalType: 'string', type: 'string' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'from', internalType: 'address', type: 'address' },
            { name: 'to', internalType: 'address', type: 'address' },
            { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [],
    },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// L2ReplicaFactory
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export const l2ReplicaFactoryABI = [
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'previousAdmin',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
            {
                name: 'newAdmin',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'AdminChanged',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'beacon',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'BeaconUpgraded',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'version',
                internalType: 'uint8',
                type: 'uint8',
                indexed: false,
            },
        ],
        name: 'Initialized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'previousOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'newOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'OwnershipTransferred',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'Paused',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'Unpaused',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'implementation',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'Upgraded',
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'originalTokenContract_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'originalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'deployReplica',
        outputs: [
            {
                name: 'replicaAddress',
                internalType: 'address',
                type: 'address',
            },
        ],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            {
                name: 'originalTokenContract_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'originalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'deployReplicaAndRemint',
        outputs: [
            { name: 'replica', internalType: 'address', type: 'address' },
        ],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'homageProtocolConfig',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'homageProtocolConfig_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'initialize',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'owner',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [],
        name: 'pause',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'paused',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'proxiableUUID',
        outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'newOwner', internalType: 'address', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [],
        name: 'unpause',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            {
                name: 'newImplementation',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'upgradeTo',
        outputs: [],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            {
                name: 'newImplementation',
                internalType: 'address',
                type: 'address',
            },
            { name: 'data', internalType: 'bytes', type: 'bytes' },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
    },
] as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export const l2ReplicaFactoryAddress = {
    10: '0xD80079595d3fF5d1acceeE4B68963671e0c25ab5',
} as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export const l2ReplicaFactoryConfig = {
    address: l2ReplicaFactoryAddress,
    abi: l2ReplicaFactoryABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ReplicaRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export const replicaRegistryABI = [
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'version',
                internalType: 'uint8',
                type: 'uint8',
                indexed: false,
            },
        ],
        name: 'Initialized',
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            {
                name: 'replicaNftContract_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'canonicalNftContract',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            {
                name: 'replicaNftContract_',
                internalType: 'address',
                type: 'address',
            },
        ],
        name: 'canonicalTokenId',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'canonicalNft_', internalType: 'address', type: 'address' },
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
            { name: 'replicaNft_', internalType: 'address', type: 'address' },
        ],
        name: 'registerReplica',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            {
                name: 'canonicalNftContract_',
                internalType: 'address',
                type: 'address',
            },
            {
                name: 'canonicalTokenId_',
                internalType: 'uint256',
                type: 'uint256',
            },
        ],
        name: 'replicaNftContract',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
    },
] as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export const replicaRegistryAddress = {
    10: '0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5',
} as const

/**
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export const replicaRegistryConfig = {
    address: replicaRegistryAddress,
    abi: replicaRegistryABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC721
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc721ABI = [
    {
        type: 'event',
        inputs: [
            { name: 'owner', type: 'address', indexed: true },
            { name: 'spender', type: 'address', indexed: true },
            { name: 'tokenId', type: 'uint256', indexed: true },
        ],
        name: 'Approval',
    },
    {
        type: 'event',
        inputs: [
            { name: 'owner', type: 'address', indexed: true },
            { name: 'operator', type: 'address', indexed: true },
            { name: 'approved', type: 'bool', indexed: false },
        ],
        name: 'ApprovalForAll',
    },
    {
        type: 'event',
        inputs: [
            { name: 'from', type: 'address', indexed: true },
            { name: 'to', type: 'address', indexed: true },
            { name: 'tokenId', type: 'uint256', indexed: true },
        ],
        name: 'Transfer',
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            { name: 'spender', type: 'address' },
            { name: 'tokenId', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ type: 'address' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            { name: 'owner', type: 'address' },
            { name: 'operator', type: 'address' },
        ],
        name: 'isApprovedForAll',
        outputs: [{ type: 'bool' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'name',
        outputs: [{ type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ name: 'owner', type: 'address' }],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            { name: 'from', type: 'address' },
            { name: 'to', type: 'address' },
            { name: 'tokenId', type: 'uint256' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'from', type: 'address' },
            { name: 'to', type: 'address' },
            { name: 'id', type: 'uint256' },
            { name: 'data', type: 'bytes' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
    },
    {
        stateMutability: 'nonpayable',
        type: 'function',
        inputs: [
            { name: 'operator', type: 'address' },
            { name: 'approved', type: 'bool' },
        ],
        name: 'setApprovalForAll',
        outputs: [],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'symbol',
        outputs: [{ type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'index', type: 'uint256' }],
        name: 'tokenByIndex',
        outputs: [{ type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [
            { name: 'owner', type: 'address' },
            { name: 'index', type: 'uint256' },
        ],
        name: 'tokenByIndex',
        outputs: [{ name: 'tokenId', type: 'uint256' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [{ name: 'tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ type: 'string' }],
    },
    {
        stateMutability: 'view',
        type: 'function',
        inputs: [],
        name: 'totalSupply',
        outputs: [{ type: 'uint256' }],
    },
    {
        stateMutability: 'payable',
        type: 'function',
        inputs: [
            { name: 'sender', type: 'address' },
            { name: 'recipient', type: 'address' },
            { name: 'tokenId', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [],
    },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link creatorRegistryABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x70027A922d4d38f6A5693d55E72D58138cc13a9f)
 */
export function readCreatorRegistry<
    TAbi extends readonly unknown[] = typeof creatorRegistryABI,
    TFunctionName extends string = string,
>(
    config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
        chainId?: keyof typeof creatorRegistryAddress
    },
) {
    return readContract({
        abi: creatorRegistryABI,
        address: creatorRegistryAddress[1],
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link homageProtocolConfigABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export function readHomageProtocolConfig<
    TAbi extends readonly unknown[] = typeof homageProtocolConfigABI,
    TFunctionName extends string = string,
>(
    config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
        chainId?: keyof typeof homageProtocolConfigAddress
    },
) {
    return readContract({
        abi: homageProtocolConfigABI,
        address: homageProtocolConfigAddress[10],
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link homageProtocolConfigABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export function writeHomageProtocolConfig<
    TFunctionName extends string,
    TMode extends WriteContractMode,
    TChainId extends number = keyof typeof homageProtocolConfigAddress,
>(
    config:
        | (Omit<
              WriteContractPreparedArgs<
                  typeof homageProtocolConfigABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof homageProtocolConfigAddress
          })
        | (Omit<
              WriteContractUnpreparedArgs<
                  typeof homageProtocolConfigABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof homageProtocolConfigAddress
          }),
) {
    return writeContract({
        abi: homageProtocolConfigABI,
        address: homageProtocolConfigAddress[10],
        ...config,
    } as unknown as WriteContractArgs<
        typeof homageProtocolConfigABI,
        TFunctionName
    >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link homageProtocolConfigABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xfffF7f0451154Ae5412558190a9e2709A4d32C88)
 */
export function prepareWriteHomageProtocolConfig<
    TAbi extends readonly unknown[] = typeof homageProtocolConfigABI,
    TFunctionName extends string = string,
>(
    config: Omit<
        PrepareWriteContractConfig<TAbi, TFunctionName>,
        'abi' | 'address'
    > & { chainId?: keyof typeof homageProtocolConfigAddress },
) {
    return prepareWriteContract({
        abi: homageProtocolConfigABI,
        address: homageProtocolConfigAddress[10],
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link l2EventLoggerABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x4066b02742f352f67DcACB4D7D74398167Aa6f1A)
 */
export function writeL2EventLogger<
    TFunctionName extends string,
    TMode extends WriteContractMode,
    TChainId extends number = keyof typeof l2EventLoggerAddress,
>(
    config:
        | (Omit<
              WriteContractPreparedArgs<typeof l2EventLoggerABI, TFunctionName>,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof l2EventLoggerAddress
          })
        | (Omit<
              WriteContractUnpreparedArgs<
                  typeof l2EventLoggerABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof l2EventLoggerAddress
          }),
) {
    return writeContract({
        abi: l2EventLoggerABI,
        address: l2EventLoggerAddress[10],
        ...config,
    } as unknown as WriteContractArgs<typeof l2EventLoggerABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link l2EventLoggerABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x4066b02742f352f67DcACB4D7D74398167Aa6f1A)
 */
export function prepareWriteL2EventLogger<
    TAbi extends readonly unknown[] = typeof l2EventLoggerABI,
    TFunctionName extends string = string,
>(
    config: Omit<
        PrepareWriteContractConfig<TAbi, TFunctionName>,
        'abi' | 'address'
    > & { chainId?: keyof typeof l2EventLoggerAddress },
) {
    return prepareWriteContract({
        abi: l2EventLoggerABI,
        address: l2EventLoggerAddress[10],
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link l2ReplicaABI}__.
 */
export function readL2Replica<
    TAbi extends readonly unknown[] = typeof l2ReplicaABI,
    TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
    return readContract({
        abi: l2ReplicaABI,
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link l2ReplicaABI}__.
 */
export function writeL2Replica<TFunctionName extends string>(
    config:
        | Omit<
              WriteContractPreparedArgs<typeof l2ReplicaABI, TFunctionName>,
              'abi'
          >
        | Omit<
              WriteContractUnpreparedArgs<typeof l2ReplicaABI, TFunctionName>,
              'abi'
          >,
) {
    return writeContract({
        abi: l2ReplicaABI,
        ...config,
    } as unknown as WriteContractArgs<typeof l2ReplicaABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link l2ReplicaABI}__.
 */
export function prepareWriteL2Replica<
    TAbi extends readonly unknown[] = typeof l2ReplicaABI,
    TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
    return prepareWriteContract({
        abi: l2ReplicaABI,
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link l2ReplicaFactoryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export function readL2ReplicaFactory<
    TAbi extends readonly unknown[] = typeof l2ReplicaFactoryABI,
    TFunctionName extends string = string,
>(
    config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
        chainId?: keyof typeof l2ReplicaFactoryAddress
    },
) {
    return readContract({
        abi: l2ReplicaFactoryABI,
        address: l2ReplicaFactoryAddress[10],
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link l2ReplicaFactoryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export function writeL2ReplicaFactory<
    TFunctionName extends string,
    TMode extends WriteContractMode,
    TChainId extends number = keyof typeof l2ReplicaFactoryAddress,
>(
    config:
        | (Omit<
              WriteContractPreparedArgs<
                  typeof l2ReplicaFactoryABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof l2ReplicaFactoryAddress
          })
        | (Omit<
              WriteContractUnpreparedArgs<
                  typeof l2ReplicaFactoryABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof l2ReplicaFactoryAddress
          }),
) {
    return writeContract({
        abi: l2ReplicaFactoryABI,
        address: l2ReplicaFactoryAddress[10],
        ...config,
    } as unknown as WriteContractArgs<
        typeof l2ReplicaFactoryABI,
        TFunctionName
    >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link l2ReplicaFactoryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0xD80079595d3fF5d1acceeE4B68963671e0c25ab5)
 */
export function prepareWriteL2ReplicaFactory<
    TAbi extends readonly unknown[] = typeof l2ReplicaFactoryABI,
    TFunctionName extends string = string,
>(
    config: Omit<
        PrepareWriteContractConfig<TAbi, TFunctionName>,
        'abi' | 'address'
    > & { chainId?: keyof typeof l2ReplicaFactoryAddress },
) {
    return prepareWriteContract({
        abi: l2ReplicaFactoryABI,
        address: l2ReplicaFactoryAddress[10],
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link replicaRegistryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export function readReplicaRegistry<
    TAbi extends readonly unknown[] = typeof replicaRegistryABI,
    TFunctionName extends string = string,
>(
    config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
        chainId?: keyof typeof replicaRegistryAddress
    },
) {
    return readContract({
        abi: replicaRegistryABI,
        address: replicaRegistryAddress[10],
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link replicaRegistryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export function writeReplicaRegistry<
    TFunctionName extends string,
    TMode extends WriteContractMode,
    TChainId extends number = keyof typeof replicaRegistryAddress,
>(
    config:
        | (Omit<
              WriteContractPreparedArgs<
                  typeof replicaRegistryABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof replicaRegistryAddress
          })
        | (Omit<
              WriteContractUnpreparedArgs<
                  typeof replicaRegistryABI,
                  TFunctionName
              >,
              'abi' | 'address'
          > & {
              mode: TMode
              chainId?: TMode extends 'prepared'
                  ? TChainId
                  : keyof typeof replicaRegistryAddress
          }),
) {
    return writeContract({
        abi: replicaRegistryABI,
        address: replicaRegistryAddress[10],
        ...config,
    } as unknown as WriteContractArgs<typeof replicaRegistryABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link replicaRegistryABI}__.
 *
 * [__View Contract on Optimism Optimism Explorer__](https://explorer.optimism.io/address/0x2f569A8392e1FC0936B429bb41499990FF9Ab7B5)
 */
export function prepareWriteReplicaRegistry<
    TAbi extends readonly unknown[] = typeof replicaRegistryABI,
    TFunctionName extends string = string,
>(
    config: Omit<
        PrepareWriteContractConfig<TAbi, TFunctionName>,
        'abi' | 'address'
    > & { chainId?: keyof typeof replicaRegistryAddress },
) {
    return prepareWriteContract({
        abi: replicaRegistryABI,
        address: replicaRegistryAddress[10],
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function readErc721<
    TAbi extends readonly unknown[] = typeof erc721ABI,
    TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
    return readContract({
        abi: erc721ABI,
        ...config,
    } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function writeErc721<TFunctionName extends string>(
    config:
        | Omit<
              WriteContractPreparedArgs<typeof erc721ABI, TFunctionName>,
              'abi'
          >
        | Omit<
              WriteContractUnpreparedArgs<typeof erc721ABI, TFunctionName>,
              'abi'
          >,
) {
    return writeContract({
        abi: erc721ABI,
        ...config,
    } as unknown as WriteContractArgs<typeof erc721ABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function prepareWriteErc721<
    TAbi extends readonly unknown[] = typeof erc721ABI,
    TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
    return prepareWriteContract({
        abi: erc721ABI,
        ...config,
    } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

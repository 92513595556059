export default function SuccessIcon(): JSX.Element {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_157_6381)">
                <path
                    d="M8.5 0C4.1 0 0.5 3.6 0.5 8C0.5 12.4 4.1 16 8.5 16C12.9 16 16.5 12.4 16.5 8C16.5 3.6 12.9 0 8.5 0ZM7.5 11.4L4.1 8L5.5 6.6L7.5 8.6L11.5 4.6L12.9 6L7.5 11.4Z"
                    fill="#32D583"
                />
            </g>
            <defs>
                <clipPath id="clip0_157_6381">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

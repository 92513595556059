export function formatOrdinal(value: number): string {
    const formatter = new Intl.PluralRules("en-US", { type: "ordinal" });
    const bucket = formatter.select(value);

    switch (bucket) {
        case "one":
            return `${value}st`;
        case "two":
            return `${value}nd`;
        case "few":
            return `${value}rd`;
        case "other":
        default:
            return `${value}th`;
    }
}

/**
 * Pluralizes simple nouns by adding an 's' at the end, if applicable. Does not
 * work for more complex cases.
 */
export function pluralizeSimple(value: number, label: string): string {
    const suffix = value !== 1 ? "s" : "";
    return `${value} ${label}${suffix}`;
}

import { Hash } from "viem";

export async function processTransaction(hash: Hash) {
    const response = await fetch(`/api/transactions/${hash}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) throw new Error("Failed to process transaction");
}

import * as Sentry from "@sentry/nextjs";

export function captureTransactionException(
    exception: unknown,
    userAddress?: string
): void {
    Sentry.captureException(exception, {
        tags: { isTransactionError: "true" },
        user: { id: userAddress },
    });
}

import useShare from "client/hooks/useShare";
import useUser from "client/hooks/useUser";
import Account from "components/account";
import Button from "components/button";
import RemintButton from "components/button/RemintButton";
import Icon from "components/icon";
import ProfilePicturesRow from "components/ProfilePicturesRow";
import { accountIdentifier } from "lib/ethereum-string";
import { pluralizeSimple } from "lib/strings";
import Link from "next/link";
import { NftResponse } from "pages/api/nfts/[address]/[tokenId]/index.page";
import { useMemo } from "react";
import { ChainId } from "server/services/ethereum/constants";
import { Nft } from "server/services/nft";
import useSWR from "swr";
import { EthereumAccount } from "types";
import { clsx } from "clsx";

type NftDetailsProps = {
    nft: Nft;
    source: string;
    hideDetails?: boolean;
} & (
    | { loadData: true; staticData?: never }
    | { loadData?: false; staticData?: NftResponse }
);

const BUTTON_CLASS = "w-32 h-8";

export default function NftDetails({
    nft,
    source,
    hideDetails,
    loadData,
    staticData,
}: NftDetailsProps): JSX.Element {
    const { user } = useUser();
    const share = useShare(
        typeof window !== "undefined" &&
            `${location.origin}/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`,
        source
    );

    const { data } = useSWR<NftResponse>(
        loadData ? `/api/nfts/${nft.address}/${nft.tokenId}` : null
    );

    const nftPageData = staticData ?? data;

    const reminters = useMemo(() => {
        const remints = nftPageData?.remints ?? nft.remints;
        return remints
            .map(({ reminter }) => reminter)
            .sort((a, b) => {
                if (a.address === user?.address) return -1;
                if (b.address === user?.address) return 1;
                if (a.ensName && !b.ensName) return -1;
                if (b.ensName && !a.ensName) return 1;
                return 0;
            });
    }, [nftPageData, nft, user]);

    const hasReminted =
        !!user &&
        !!reminters?.find((reminter) => reminter.address === user.address);

    const isOwner = !!user && user.address === nftPageData?.owner?.address;
    const isCreator = !!user && user.address === nft.creator?.address;
    const isOwnerOrCreator = isOwner || isCreator;

    return (
        <div className="h-32 pb-3 md:pb-8 flex flex-col gap-2 justify-end pointer-events-none">
            <div className="flex items-center justify-between gap-5">
                <Account
                    account={nft.creator}
                    size="extrasmall"
                    className="text-sm pointer-events-auto text-gray-600"
                    isLink
                />
                {!hideDetails && (
                    <div className="flex justify-end items-center text-sm text-gray-600 gap-1 text-right">
                        <ProfilePicturesRow accounts={reminters} />
                        <div className="leading-tight line-clamp-2 text-ellipsis">
                            <RemintersLabel reminters={reminters} user={user} />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex items-end justify-between gap-2">
                <Link
                    href={`/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`}
                    className="flex items-center text-lg pointer-events-auto"
                >
                    {nft.name}
                </Link>
                <div className="flex gap-4">
                    <button className="pointer-events-auto" onClick={share}>
                        <Icon name="share" className="h-6 w-6" />
                    </button>
                    {isOwnerOrCreator ? (
                        hideDetails ? null : (
                            <Link
                                className="pointer-events-auto"
                                href={`/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`}
                            >
                                <Button
                                    variant="secondary"
                                    size="small"
                                    className="w-32"
                                >
                                    View NFT
                                </Button>
                            </Link>
                        )
                    ) : hasReminted ? (
                        <Link className="pointer-events-auto" href="/gallery">
                            <Button
                                variant="secondary"
                                size="small"
                                className={BUTTON_CLASS}
                            >
                                View in gallery
                            </Button>
                        </Link>
                    ) : (
                        <RemintButton
                            size="small"
                            nft={nft}
                            remintContractAddress={
                                nftPageData?.remintContractAddress
                            }
                            disabled={hasReminted}
                            className={clsx(
                                BUTTON_CLASS,
                                "pointer-events-auto"
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

interface RemintersLabelProps {
    reminters: EthereumAccount[];
    user?: EthereumAccount | null;
}

function RemintersLabel({
    reminters,
    user,
}: RemintersLabelProps): JSX.Element | null {
    switch (reminters.length) {
        case 0:
            return null;
        case 1:
            return (
                <span>
                    Reminted by{" "}
                    <Link
                        className="pointer-events-auto"
                        href={`/user/${reminters[0].address}`}
                    >
                        {identifier(reminters[0], user)}
                    </Link>
                </span>
            );
        case 2:
            return (
                <span>
                    Reminted by{" "}
                    <Link
                        className="pointer-events-auto"
                        href={`/user/${reminters[0].address}`}
                    >
                        {identifier(reminters[0], user)}
                    </Link>{" "}
                    and{" "}
                    <Link
                        href={`/user/${reminters[1].address}`}
                        className="pointer-events-auto inline"
                    >
                        {identifier(reminters[1], user)}
                    </Link>
                </span>
            );
        default:
            return (
                <span>
                    Reminted by{" "}
                    <Link
                        className="pointer-events-auto"
                        href={`/user/${reminters[0].address}`}
                    >
                        {identifier(reminters[0], user)}
                    </Link>
                    ,{" "}
                    <Link
                        className="pointer-events-auto"
                        href={`/user/${reminters[1].address}`}
                    >
                        {identifier(reminters[1], user)}
                    </Link>
                    , and {pluralizeSimple(reminters.length - 2, "other")}
                </span>
            );
    }
}

function identifier(
    account: EthereumAccount,
    user?: EthereumAccount | null
): string {
    return account.address === user?.address
        ? "you"
        : accountIdentifier(account);
}

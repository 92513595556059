import { clsx } from "clsx";
import type { Nft } from "server/services/nft";
import styles from "./NftAsset.module.css";
import {
    ImgHTMLAttributes,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import Loader from "components/loader";
import Icon from "components/icon";

interface NftAssetProps {
    nft: Pick<
        Nft,
        "imageUrl" | "imagePreviewLargeUrl" | "animationUrl" | "type"
    >;
    fullResolution?: boolean;
}

export default function NftAsset({
    nft,
    fullResolution = true,
}: NftAssetProps): JSX.Element {
    const imageUrl = fullResolution
        ? nft.imageUrl
        : nft.imagePreviewLargeUrl ?? nft.imageUrl;

    switch (nft.type) {
        case "audio":
            return (
                <AudioNft imageUrl={imageUrl} animationUrl={nft.animationUrl} />
            );
        case "video":
            return (
                <VideoNft imageUrl={imageUrl} animationUrl={nft.animationUrl} />
            );
        default:
            return <DefaultContent url={imageUrl} />;
    }
}

interface AnimationNftAssetProps {
    imageUrl: string | null;
    animationUrl: string | null;
}

function AudioNft({
    imageUrl,
    animationUrl,
}: AnimationNftAssetProps): JSX.Element {
    const audioRef = useRef<HTMLAudioElement>(null);

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);

    const readyState = audioRef.current?.readyState;

    useEffect(() => {
        if (readyState && readyState >= 3) {
            setIsAudioLoaded(true);
        }
    }, [readyState]);

    if (!animationUrl) {
        return <DefaultContent url={imageUrl} />;
    }

    const isLoaded = isImageLoaded && isAudioLoaded;

    return (
        <div
            className={clsx(
                "h-full max-w-auto relative mx-auto",
                styles.audioContainer
            )}
        >
            {!isLoaded && (
                <div className="h-full w-full flex items-center justify-center">
                    <Loader size="medium" />
                </div>
            )}
            <NftImage
                src={imageUrl}
                className={clsx("object-contain h-full", !isLoaded && "hidden")}
                onImageLoad={() => setIsImageLoaded(true)}
            />
            <audio
                ref={audioRef}
                controls
                controlsList="nodownload"
                src={animationUrl}
                className={clsx(
                    "absolute bottom-1.5 w-full px-1.5",
                    !isLoaded && "hidden"
                )}
                onClick={(event) => event.preventDefault()}
                onCanPlay={() => setIsAudioLoaded(true)}
            />
        </div>
    );
}

function VideoNft({
    imageUrl,
    animationUrl,
}: AnimationNftAssetProps): JSX.Element {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isLoaded, setIsLoaded] = useState(false);

    const readyState = videoRef.current?.readyState;

    useEffect(() => {
        if (readyState && readyState >= 3) {
            setIsLoaded(true);
        }
    }, [readyState]);

    if (!animationUrl) {
        return <DefaultContent url={imageUrl} />;
    }

    return (
        <>
            {!isLoaded && (
                <div className="h-full max-w-auto flex flex-col justify-center">
                    <Loader size="medium" />
                </div>
            )}
            <video
                ref={videoRef}
                autoPlay
                playsInline
                loop
                muted
                controls
                controlsList="nodownload"
                disablePictureInPicture
                src={animationUrl}
                className={clsx(
                    "h-full max-w-auto mx-auto",
                    !isLoaded && "hidden"
                )}
                onClick={(event) => event.preventDefault()}
                onCanPlay={() => setIsLoaded(true)}
            />
        </>
    );
}

interface DefaultContentProps {
    url: string | null;
}

function DefaultContent({ url }: DefaultContentProps): JSX.Element {
    return (
        <NftImage
            src={url}
            className="object-contain max-h-full max-w-auto mx-auto"
        />
    );
}

interface NftImageProps
    extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
    src: string | null;
    onImageLoad?: () => void;
}

function NftImage({
    className,
    src,
    onImageLoad,
    ...imgProps
}: NftImageProps): JSX.Element {
    const imgRef = useRef<HTMLImageElement>(null);

    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = useCallback(() => {
        setIsLoaded(true);
        onImageLoad?.();
    }, [onImageLoad]);

    useEffect(() => {
        if (imgRef.current?.complete) {
            onLoad();
        }
    }, [imgRef.current?.complete, onLoad]);

    if (!src) {
        return (
            <div className={`justify-center items-center flex ${className}`}>
                <Icon name="image" className="h-12 w-12" />
            </div>
        );
    }

    return (
        <>
            {!isLoaded && (
                <div
                    className={`justify-center items-center flex ${className}`}
                >
                    <Loader size="medium" />
                </div>
            )}
            <img // eslint-disable-line @next/next/no-img-element
                ref={imgRef}
                src={src}
                className={clsx(className, !isLoaded && "hidden")}
                alt="NFT"
                onLoad={onLoad}
                {...imgProps}
            />
        </>
    );
}

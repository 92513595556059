import useToast from "context/toast";
import { useCallback } from "react";
import { ChainId } from "server/services/ethereum/constants";
import { getNetwork, switchNetwork } from "wagmi/actions";
import ErrorToastContent from "./ErrorToastContent";

export default function useChangeConnectedChain() {
    const { toast } = useToast();

    return useCallback(
        async (chainId: ChainId) => {
            const { chain, chains } = await getNetwork();

            if (chain?.id === chainId) return;

            try {
                await switchNetwork({ chainId });
            } catch {
                const targetChain = chains.find(
                    (chain) => chain.id === chainId
                );

                const message = targetChain
                    ? `Please switch to the ${targetChain.name} network (id ${chainId}) in your wallet`
                    : `Please switch to network id ${chainId} in your wallet`;

                toast({
                    content: <ErrorToastContent error={message} />,
                });
            }
        },
        [toast]
    );
}

import { getBlockExplorerUrl } from "client/lib/blockExplorer";
import { ChainIcon } from "connectkit";
import * as EthereumString from "lib/ethereum-string";
import { ChainId } from "server/services/ethereum/constants";
import { Hash } from "viem";

interface ErrorToastContentProps {
    chainId: ChainId;
    hash: Hash;
}

export default function ErrorToastContent({
    chainId,
    hash,
}: ErrorToastContentProps): JSX.Element {
    return (
        <div className="px-2.5 py-1">
            <span className="text-error-600">Transaction Failure</span>
            <a
                href={getBlockExplorerUrl(chainId, hash)}
                target="_blank"
                rel="noreferrer"
            >
                <div className="flex gap-1.5 font-bold items-center">
                    <ChainIcon id={chainId} size={32} />
                    <span className="underline">
                        {EthereumString.shortenTransaction(hash)}
                    </span>
                    <span>↗</span>
                </div>
            </a>
        </div>
    );
}

import { ChainId } from "server/services/ethereum/constants";
import { Hash } from "viem";

const blockExplorerUrlMap: Record<number, string> = {
    1: "https://etherscan.io/tx/",
    4: "https://rinkeby.etherscan.io/tx/",
    5: "https://goerli.etherscan.io/tx/",
    10: "https://optimistic.etherscan.io/tx/",
    69: "https://kovan-optimistic.etherscan.io/tx/",
    420: "https://blockscout.com/optimism/goerli/tx/",
};

export function getBlockExplorerUrl(chainId: ChainId, hash: Hash) {
    return blockExplorerUrlMap[chainId] + hash;
}

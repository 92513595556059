import { prepareWriteL2Replica, writeL2Replica } from "contracts/generated";
import { Address } from "lib/zod/types";
import { ChainId } from "server/services/ethereum/constants";
import { getPublicClient, getWalletClient } from "wagmi/actions";

export default async function remint(
    remintContractAddress: Address,
    remintPrice: bigint
) {
    const [publicClient, walletClient] = await Promise.all([
        getPublicClient({ chainId: ChainId.Optimism }),
        getWalletClient({ chainId: ChainId.Optimism }),
    ]);

    if (!walletClient) throw new Error("No connected account");
    const [address] = await walletClient.requestAddresses();

    const { request, mode } = await prepareWriteL2Replica({
        address: remintContractAddress,
        functionName: "mintReplica",
        args: [address, address, address],
        value: remintPrice,
    });

    const gas = await publicClient.estimateContractGas(request);

    return writeL2Replica({
        ...request,
        mode,
        gas: (gas * 110n) / 100n,
    });
}

import useRemint from "client/hooks/remint";
import { RemintedNft } from "client/hooks/remint/useRemint";
import useUser from "client/hooks/useUser";
import { track } from "client/lib/analytics";
import Icon from "components/icon";
import SuccessIcon from "components/icon/SuccessIcon";
import Loader from "components/loader";
import { useModal as useConnectKitModal } from "connectkit";
import { captureTransactionException } from "lib/sentry";
import { Address } from "lib/zod/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button, { ButtonProps, ButtonVariant } from "./Button";

interface RemintButtonProps extends Omit<ButtonProps, "ref" | "children"> {
    nft: RemintedNft;
    remintContractAddress: Address | null | undefined;
}

type State = "not ready" | "initialized" | "loading" | "success";

export default function RemintButton({
    nft,
    remintContractAddress,
    variant = "primary",
    ...props
}: RemintButtonProps): JSX.Element {
    const [state, setState] = useState<State>(
        remintContractAddress === undefined ? "not ready" : "initialized"
    );
    const [clicked, setClicked] = useState(false);
    const { user } = useUser();
    const { openSIWE } = useConnectKitModal();
    const remint = useRemint();

    const properties = useMemo(
        () => ({
            address: nft.address,
            tokenId: nft.tokenId.toString(),
        }),
        [nft]
    );

    const handleRemint = useCallback(() => {
        async function execute() {
            if (!user || remintContractAddress === undefined) throw new Error();
            setState("loading");
            track("event:remint-button:loading", properties);
            try {
                await remint(nft, remintContractAddress);
                setState("success");
                track("event:remint-button:success", properties);
            } catch (error) {
                setState("initialized");
                track("event:remint-button:error", properties);
                captureTransactionException(error, user.address);
            }
        }

        void execute();
    }, [user, remint, nft, remintContractAddress, properties]);

    const handleClick = useCallback(
        (event) => {
            event.preventDefault();
            track("click:remint-button", properties);

            if (user) {
                handleRemint();
                return;
            }

            setClicked(true);
            openSIWE(true);
        },
        [user, handleRemint, openSIWE, properties]
    );

    useEffect(() => {
        if (!clicked || !user?.address) return;
        setClicked(false);
        handleRemint();
    }, [clicked, handleRemint, user?.address]);

    useEffect(() => {
        if (state === "not ready" && remintContractAddress !== undefined) {
            setState("initialized");
        }
    }, [state, remintContractAddress]);

    return (
        <Button
            onClick={handleClick}
            disabled={["not ready", "loading"].includes(state)}
            variant={variant}
            {...props}
        >
            {getButtonContent(state, variant)}
        </Button>
    );
}

function getButtonContent(state: State, variant: ButtonVariant): JSX.Element {
    switch (state) {
        case "not ready":
            return (
                <Loader
                    variant={variant === "secondary" ? "dark" : "light"}
                    size="small"
                />
            );
        case "initialized":
            return (
                <>
                    <span className="mr-2">Remint</span>
                    <Icon name="remint" />
                </>
            );
        case "loading":
            return (
                <>
                    <span className="mr-2">Reminting</span>
                    <Loader
                        variant={variant === "secondary" ? "dark" : "light"}
                        size="small"
                    />
                </>
            );
        case "success":
            return (
                <>
                    <span className="mr-2">Reminted</span>
                    <SuccessIcon />
                </>
            );
    }
}

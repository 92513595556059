import Icon from "components/icon";

interface ErrorToastContentProps {
    error: string;
}

export default function ErrorToastContent({
    error,
}: ErrorToastContentProps): JSX.Element {
    return (
        <div className="flex items-center gap-3">
            <div className="h-8 w-8 flex items-center">
                <Icon name="error" className="h-8 w-8 text-error-400" />
            </div>
            <div className="text-gray-600">{error}</div>
        </div>
    );
}
